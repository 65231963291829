import { imageLoader } from 'next-image-loader/build/image-loader'
import { IMAGE_LOADER_URL } from './helper/environment'

// write self-define a custom loader
// (resolverProps: { src: string; width: number; quality?: number }) => string
imageLoader.loader = ({ src, width, quality }) => {
  const extension = src.split(/[#?&]/)[0].split('.').pop().trim()
  const passedWidth = src.match(/width=\d+/)
  const passedWidthValue = passedWidth ? passedWidth[0].split('=')[1] : null
  // return early if the image is localy saved
  if (src.startsWith('/')) return src
  // check for extensions, so we don't forward svg files
  if (['png', 'jpeg', 'jpg', 'webp'].includes(extension))
  // removed &width=${Math.min(width, 2000)} because it is upscaling all images..
  return `${IMAGE_LOADER_URL}?url=${encodeURIComponent(src)}&quality=${quality || 80}&format=png&width=${passedWidthValue ? passedWidthValue : Math.min(width, 1200)}`
  .replaceAll("%20", "")
  .replaceAll("%2F", "/")
  .replaceAll("%25", "%")
  .replaceAll("%3A", ":")

  return src
}