export const GRAPHQL_PREVIEW_URL = process.env.NEXT_PUBLIC_GRAPHQL_PREVIEW_URL ?? ''
export const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL ?? ''
export const PIMCORE_URL = process.env.NEXT_PUBLIC_PIMCORE_URL ?? ''
export const NODE_ENV = process.env.NODE_ENV
export const IS_DEVELOPMENT = NODE_ENV === 'development'
export const IS_PRODUCTION = NODE_ENV === 'production'
export const PORT = process.env.PORT ?? '3000'
export const LOCALHOST = `http://localhost:${PORT}` as const
export const INSTAGRAM_KEY = process.env.NEXT_PUBLIC_INSTAGRAM_USER_TOKEN ?? ''
export const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS ?? ''
export const VENDURE_ASSET_URL = process.env.NEXT_PUBLIC_VENDURE_ASSET_URL ?? ''
export const TYPESENSE_HOST = process.env.NEXT_PUBLIC_TYPESENSE_HOST ?? ''
export const TYPESENSE_PORT = process.env.NEXT_PUBLIC_TYPESENSE_PORT ?? ''
export const TYPESENSE_PROTOCOL = process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL ?? ''
export const TYPESENSE_SEARCH_ONLY_API_KEY = process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_ONLY_API_KEY ?? ''
export const VENDURE_PRODUCT_INDEX = process.env.NEXT_PUBLIC_VENDURE_PRODUCT_INDEX ?? ''
export const IMAGE_LOADER_URL = process.env.NEXT_PUBLIC_IMAGE_LOADER_URL ?? ''
export const STOCKER_LOGIN_PAGE = process.env.NEXT_PUBLIC_STOCKER_LOGIN_URL ?? 'https://stocker-kaminsysteme.com/auth/login'
export const NEXT_PUBLIC_STOCKER_CART_URL = process.env.NEXT_PUBLIC_STOCKER_CART_URL ?? 'https://stocker-kaminsysteme.com/cart'
export const STOCKER_URL = process.env.NEXT_PUBLIC_STOCKER_URL ?? 'https://stocker-kaminsysteme.com/'
export const STOCKER_LIST_CONFIGURATIONS_URL = process.env.NEXT_PUBLIC_STOCKER_LIST_CONFIGURATIONS_URL ?? 'https://stocker-kaminsysteme.com/account/configurations'
